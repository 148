import { Spinner } from '@cian/ui-kit';
import * as React from 'react';

import { IPaymentSummarySchema } from 'shared/repositories/dailyrent/entities/payment_summary/PaymentSummarySchema';

import * as styles from './BookingInfo.css';
import { Sections } from './components';

interface IBookingInfoConstructorProps {
  paymentSummary: IPaymentSummarySchema;
  isLoading: boolean;
}

export const BookingInfo = ({ paymentSummary, isLoading }: IBookingInfoConstructorProps) => {
  return (
    <div className={styles['block']}>
      {isLoading && (
        <div className={styles['spinner']}>
          <Spinner size={20} />
        </div>
      )}
      <Sections>{[paymentSummary]}</Sections>
    </div>
  );
};
