import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkDailyrentPrice } from 'shared/actions/checkDailyrentPrice/actions';
import { setGuestsCount, toggleGuestsModal } from 'shared/actions/dailyrentBookingGuests';
import { GuestsControlDesktop } from 'shared/components/GuestsControl';
import { DEFAULT_VALUE } from 'shared/constants/guests';
import {
  trackDropDownOpen,
  trackGuestsApply,
  trackGuestsLocalChange,
  trackGuestsReset,
} from 'shared/containers/GuestsControlContainer/tracking';
import {
  selectDailyrentBookingCalendar,
  selectDailyrentBookingGeneral,
  selectDailyrentBookingGuests,
} from 'shared/selectors/dailyrentBooking';
import { getSafeGuestsCount } from 'shared/utils/getSafeGuestsCount';

import { useSetNormalizedGuestsCountIntoUrl } from './helpers';
import { updateDailyrentUrlParameters } from '../BookingCalendarInputContainer/helpers';

interface IAnalitycsState {
  wasPlusEventSent: boolean;
  wasMinusEventSent: boolean;
}

export function GuestsControlContainer() {
  const dispatch = useDispatch();
  const { dates } = useSelector(selectDailyrentBookingCalendar);
  const { isLoading } = useSelector(selectDailyrentBookingGeneral);
  const { isOpen, guestsCount, minGuestsCount, maxGuestsCount } = useSelector(selectDailyrentBookingGuests);

  const [localValue, setLocalValue] = React.useState(guestsCount);
  const analyticsStateRef = React.useRef<IAnalitycsState>({ wasPlusEventSent: false, wasMinusEventSent: false });

  useSetNormalizedGuestsCountIntoUrl();

  const onChange = React.useCallback(
    (value: number, mode: 'increase' | 'decrease' | 'manual') => {
      if (mode === 'increase' && !analyticsStateRef.current.wasPlusEventSent) {
        // Нужно отправлять трекинг только раз за серию одинаковых действий
        analyticsStateRef.current.wasPlusEventSent = true;
        analyticsStateRef.current.wasMinusEventSent = false;
        trackGuestsLocalChange('increase');
      } else if (mode === 'decrease' && !analyticsStateRef.current.wasMinusEventSent) {
        // Нужно отправлять трекинг только раз за серию одинаковых действий
        analyticsStateRef.current.wasMinusEventSent = true;
        analyticsStateRef.current.wasPlusEventSent = false;
        trackGuestsLocalChange('decrease');
      }

      setLocalValue(getSafeGuestsCount(value, minGuestsCount, maxGuestsCount));
    },
    [minGuestsCount, maxGuestsCount, guestsCount, analyticsStateRef],
  );

  const onApply = React.useCallback(async () => {
    trackGuestsApply(localValue);
    dispatch(setGuestsCount(localValue));
    dispatch(toggleGuestsModal(false));

    const errorMessage = await dispatch(
      checkDailyrentPrice({ dates: { start: dates.from as string, end: dates.to as string }, guestsCount: localValue }),
    );

    if (errorMessage) {
      return;
    }

    const newUrl = updateDailyrentUrlParameters({
      currentUrl: window.location.href,
      guestsCount: localValue,
    });

    history.replaceState(undefined, '', newUrl);
  }, [dispatch, localValue, dates]);

  const onReset = React.useCallback(() => {
    trackGuestsReset();
    setLocalValue(DEFAULT_VALUE);
  }, []);

  const onControlClick = React.useCallback(() => {
    // если во время клика дропдаун не был открыт, то отправляем событие открытия
    if (!isOpen) {
      trackDropDownOpen();
    }

    dispatch(toggleGuestsModal(!isOpen));
  }, [dispatch, isOpen]);

  const onClose = React.useCallback(() => {
    dispatch(toggleGuestsModal(false));
    setLocalValue(guestsCount);
  }, [dispatch, guestsCount]);

  return (
    <GuestsControlDesktop
      isModalOpen={isOpen}
      isLoading={isLoading}
      localValue={localValue}
      appliedValue={guestsCount}
      minValue={minGuestsCount}
      maxValue={maxGuestsCount}
      onControlClick={onControlClick}
      onChange={onChange}
      onResetClick={onReset}
      onApplyClick={onApply}
      onClose={onClose}
    />
  );
}
