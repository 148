import cx from 'clsx';
import * as React from 'react';

import { NoImage } from 'shared/components/NoImage';
import { PriceDiscount } from 'shared/components/PriceDiscount';

import * as styles from './SimilarOfferItem.css';
import { ISimilarOffer } from '../../../../store/similar';
import { Underground } from '../Underground';

export interface ISimilarOfferItemProps {
  offer: ISimilarOffer;
  position: number;
  className?: string;
  withPhotoLabel?: boolean;

  onClick(offer: ISimilarOffer, position: number): void;
}

export const SimilarOfferItem: React.FC<ISimilarOfferItemProps> = ({
  offer,
  position,
  className,
  withPhotoLabel,
  onClick,
}) => {
  const { formattedPrice, title, url, photoUrl, photoLabel, address, underground, discount } = offer;

  const onClickHandler = React.useCallback(() => onClick(offer, position), [onClick, offer, position]);

  const price = discount ? discount.newPrice : formattedPrice;
  const showTwoAddressRows = (underground?.name && !discount) || (discount && !underground?.name);
  const showOneAddressRow = Boolean(discount && underground?.name);

  return (
    <div className={cx(styles['item'], className)}>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href={url}
        data-testid="similarOfferItemLink"
        className={styles['link']}
        target="_blank"
        onClick={onClickHandler}
      >
        <div className={styles['image_block']}>
          {photoUrl ? <img src={photoUrl} alt={title} className={styles['image']} /> : <NoImage />}
          {withPhotoLabel && photoLabel && photoLabel.name && (
            <div data-testid="similarOfferItemPhotoLabel" className={cx(styles['photo_label'], styles['ellipsis'])}>
              {photoLabel.name}
            </div>
          )}
        </div>
        <div className={styles['content_block']}>
          <div className={cx(styles['price'], styles['ellipsis'])}>{price}</div>
          {discount && (
            <div className={cx(styles['price'], styles['ellipsis'])} data-testid="similarOfferItemDiscount">
              <PriceDiscount oldPrice={discount.oldPrice} percent={discount.percent} />
            </div>
          )}
          <div className={cx(styles['title'], styles['ellipsis'])}>{title}</div>
          {underground && underground.name && (
            <Underground data-testid="similarOfferItemUnderground" underground={underground} />
          )}
          {address && (
            <div
              data-testid="similarOfferItemAddress"
              className={cx(
                styles['address'],
                showOneAddressRow && styles['address_one_row'],
                showTwoAddressRows && styles['address_two_rows'],
              )}
            >
              {address}
            </div>
          )}
        </div>
      </a>
    </div>
  );
};
