import { BookingCalendarModal } from '@cian/frontend-dailyrent-calendar';
import { Outside } from '@cian/ui-kit';
import * as React from 'react';

import { BookingCalendarLayout } from 'shared/components/BookingCalendarLayout';

import { useBookingCalendarContainer } from './hooks';
import { BookingCalendarInput } from '../../components/BookingCalendarInput';

export const BookingCalendarInputContainer = () => {
  const {
    isRedesign,
    isOpen,
    isInitialLoading,
    isLoading,
    isSelectedDatesInfoLoading,
    globalError,
    initialDates,
    externalError,
    daysAvailability,
    onChange,
    onApply,
    onError,
    onShow,
    onReset,
    inputText,
    isDefaultInputText,
    onInputClick,
    onOutsideClick,
  } = useBookingCalendarContainer();

  return (
    <Outside onOutside={onOutsideClick} active={isOpen} insideRefs={undefined}>
      <BookingCalendarLayout>
        <BookingCalendarInput
          title={inputText}
          onClick={onInputClick}
          isDefaultText={isDefaultInputText}
          isDisabled={isLoading}
        />
        {isOpen && (
          <BookingCalendarModal
            isRedesign={isRedesign}
            isInitialLoading={isInitialLoading}
            isSelectedDatesInfoLoading={isSelectedDatesInfoLoading}
            globalError={globalError}
            externalError={externalError}
            initialFrom={initialDates.from || null}
            initialTo={initialDates.to || null}
            daysAvailability={daysAvailability || []}
            footerText={null} // Нужно только для мобильной версии
            showResetButton
            onApply={onApply}
            onChange={onChange}
            onClose={onOutsideClick}
            onError={onError}
            onShow={onShow}
            onReset={onReset}
          />
        )}
      </BookingCalendarLayout>
    </Outside>
  );
};
