import { Button, Image } from '@cian/ui-kit';
import * as React from 'react';

import { ResultScreen } from 'shared/components/NewbuildingBookingForm/ResultScreen';
import { NBSP } from 'shared/constants';

import imageSrc from './assets/error.png';

interface IErrorScreenProps {
  onClick(): void;
}

export const ErrorScreen = ({ onClick }: IErrorScreenProps) => {
  return (
    <ResultScreen
      title="Что-то пошло не так"
      subtitle={
        <>
          Проверьте интернет
          <br />
          или{NBSP}попробуйте обновить
        </>
      }
      button={
        <Button theme="fill_primary" size="L" color="current_color" onClick={onClick}>
          Повторить
        </Button>
      }
      image={<Image src={imageSrc} width={100} height={100} objectFit="contain" preloader={false} />}
    />
  );
};
