import { LinkButton } from '@cian/ui-kit/button';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackOnlineBookingBookButtonClick } from 'shared/containers/AsideContacts/OnlineBookingBookButtonContainer/tracking';
import { useDailyrentCheckoutPageUrl } from 'shared/hooks/useDailyrentCheckoutPageUrl';
import { selectOfferId } from 'shared/selectors';
import { selectDailyrentBookingGeneral } from 'shared/selectors/dailyrentBooking';

export const OnlineBookingBookButtonContainer = () => {
  const realtyId = useSelector(selectOfferId);
  const { isLoading } = useSelector(selectDailyrentBookingGeneral);

  const checkoutPageUrl = useDailyrentCheckoutPageUrl();

  const onClick = React.useCallback(() => {
    trackOnlineBookingBookButtonClick(realtyId);
  }, [realtyId]);

  return (
    <LinkButton
      href={isLoading ? undefined : checkoutPageUrl}
      onClick={isLoading ? undefined : onClick}
      role="button"
      theme="fill_primary"
      size="M"
      fullWidth
      target="_blank"
      rel="noopener noreferrer"
    >
      Забронировать
    </LinkButton>
  );
};
