import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSourceParams } from 'shared/utils/getSourceParams';

import { trackModalOpen, trackModalOpenChatbot } from './tracking';
import { closeChat } from '../../actions/chat';
import { ChatPopup } from '../../components/ChatPopup';
import { getAgentAvailability, offerDataSelector } from '../../selectors';
import { offerChatSelector } from '../../selectors/offerChat';
import { getCustomUrl } from '../../utils/tracking/getCustomUrl';
import { getProduct } from '../../utils/tracking/getProduct';
import { getProducts } from '../../utils/tracking/get_products';

export const ChatPopupContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { offer, agent, user, rosreestrInfo, kp } = useSelector(offerDataSelector);
  const { isChatOpened, chatId, type, defaultMessage, shouldSendDefaultMessage, source, messagePayload } =
    useSelector(offerChatSelector);
  const agentAvailability = useSelector(getAgentAvailability);

  const isAgentNotAvailable = user && agent && agentAvailability && !agentAvailability.available;

  let gaProducts;

  if (isAgentNotAvailable) {
    gaProducts = [getProduct(offer, agent)];
  } else {
    const products = getProducts({ agent, offer, kpId: kp?.id || null, rosreestrInfo });
    gaProducts = [{ ...products, variant: products.variant ? products.variant.split('/') : [] }];
  }

  const label = React.useMemo(() => getCustomUrl(offer, agent), [offer, agent]);

  const sourceParams = getSourceParams();

  React.useEffect(() => {
    if (isChatOpened) {
      if (type === 'open_chatbot') {
        const id = offer.id;
        trackModalOpenChatbot({ defaultMessage, id });
      } else {
        trackModalOpen({ label, chatId, type, source });
      }
    }
  }, [isChatOpened, label, chatId, type, source]);

  const onClose = React.useCallback(() => dispatch(closeChat()), [dispatch]);

  if (!isChatOpened) {
    return null;
  }

  return (
    <ChatPopup
      offer={offer}
      onClose={onClose}
      gaProducts={JSON.stringify(gaProducts)}
      gaLabel={label}
      defaultMessage={defaultMessage}
      shouldSendDefaultMessage={shouldSendDefaultMessage}
      messagePayload={messagePayload}
      source={sourceParams?.source}
      isChatbot={type === 'open_chatbot'}
    />
  );
};
