import { EDailyrentBookingGuestsActionTypes, TSetGuestsCount, TToggleGuestsModal } from './types';

export const toggleGuestsModal = (isOpen?: boolean): TToggleGuestsModal => ({
  type: EDailyrentBookingGuestsActionTypes.ToggleGuestsModal,
  payload: isOpen,
});

export const setGuestsCount = (count: number): TSetGuestsCount => ({
  type: EDailyrentBookingGuestsActionTypes.SetGuestsCount,
  payload: count,
});
