import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { selectWasChatOpened, selectChatOnboardingAvailable } from 'shared/selectors/chatOnboarding';
import { isConsultantChatOpenedSelector } from 'shared/selectors/newbuildingConsultant';

import { useVisibility } from './useVisibility';
import { CHAT_ONBOARDING_TEXT } from '../constants';
import { TChatOnboardingType } from '../types';
import { getChatOnboardingCookie, setChatOnboardingCookie } from '../utils';

interface IChatOnboardingResult {
  open: boolean;
  onboardingText: string;
  closeHandler: () => void;
  ref: React.MutableRefObject<HTMLElement | null>;
}

// TODO: Удалить в CD-226224/CD-225475.Онбординги на месяц.
export const useChatOnboarding = (onboardingType: TChatOnboardingType): IChatOnboardingResult => {
  const [open, setOpen] = useState(false);
  const { ref, isVisible } = useVisibility();
  const { config } = useApplicationContext();

  const builderChatWasOpened = useSelector(selectWasChatOpened);
  const isConsultantChatOpen = useSelector(isConsultantChatOpenedSelector);
  const isOnboardingAvailable = useSelector(selectChatOnboardingAvailable);
  const isOnboardingShown = getChatOnboardingCookie(onboardingType);
  const onboardingText = config.get<string>(CHAT_ONBOARDING_TEXT[onboardingType]) ?? '';

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (isOnboardingAvailable && !isOnboardingShown) {
      setOpen(isVisible);

      if (isVisible) {
        setChatOnboardingCookie(onboardingType);
      }
    }
  }, [isOnboardingAvailable, isOnboardingShown, isVisible, onboardingType]);

  useEffect(() => {
    if (
      (onboardingType === 'consultant' && open && isConsultantChatOpen) ||
      (onboardingType === 'builder' && open && builderChatWasOpened)
    ) {
      closeHandler();
    }
  }, [open, builderChatWasOpened, closeHandler, onboardingType, isConsultantChatOpen]);

  return {
    open,
    onboardingText,
    closeHandler,
    ref,
  };
};
