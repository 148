import { Button } from '@cian/ui-kit';
import cx from 'clsx';
import React from 'react';

import * as styles from './styles.css';
import { IGuestsControlProps } from '../types';

interface IProps extends Pick<IGuestsControlProps, 'onResetClick' | 'onApplyClick'> {
  isMobile?: boolean;
}

export function GuestsModalFooter({ isMobile, onResetClick, onApplyClick }: IProps) {
  return (
    <div className={cx(styles['footer'], isMobile && styles['mobile'])}>
      <div className={styles['button']}>
        <Button theme="fill_secondary" size={isMobile ? 'M' : 'XS'} fullWidth={isMobile} onClick={onResetClick}>
          Сбросить
        </Button>
      </div>
      <div className={styles['button']}>
        <Button theme="fill_primary" size={isMobile ? 'M' : 'XS'} fullWidth={isMobile} onClick={onApplyClick}>
          Применить
        </Button>
      </div>
    </div>
  );
}
