import { IconButton, Spinner } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './ChatPopup.css';
import { IOffer } from '../../types/offerData';

interface IChatPopupProps {
  offer: IOffer;
  gaLabel?: string;
  gaProducts?: string;
  defaultMessage?: string;
  messagePayload?: string;
  shouldSendDefaultMessage?: boolean;
  source?: string | null;
  isChatbot: boolean;
  onClose(): void;
}

export const ChatPopup: React.FC<IChatPopupProps> = ({
  offer,
  onClose,
  gaProducts,
  gaLabel,
  defaultMessage,
  messagePayload,
  shouldSendDefaultMessage,
  source,
  isChatbot,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const onLoad = () => setIsLoading(false);

  let src: string;

  const { id, dealType, offerType } = offer;

  if (isChatbot) {
    src =
      `/dialogs/?hostType=frame&chatbot=1&defaultMessagePayload=${id}` +
      `${gaProducts ? '&gaProducts=' + encodeURIComponent(gaProducts) : ''}` +
      `${gaLabel ? '&gaLabel=' + encodeURIComponent(gaLabel) : ''}` +
      `&source=Card&pageType=Card` +
      `${defaultMessage ? '&defaultMessage=' + encodeURIComponent(defaultMessage) : ''}` +
      `${source ? `&parentSource=${source}` : ''}`;
  } else {
    /* istanbul ignore next */
    src =
      `/dialogs/?hostType=frame&offerId=${id}` +
      `&dealType=${dealType}&offerType=${offerType}` +
      `${gaProducts ? '&gaProducts=' + encodeURIComponent(gaProducts) : ''}` +
      `${gaLabel ? '&gaLabel=' + encodeURIComponent(gaLabel) : ''}` +
      `${defaultMessage ? '&defaultMessage=' + encodeURIComponent(defaultMessage) : ''}` +
      `${messagePayload ? '&defaultMessagePayload=' + encodeURIComponent(messagePayload) : ''}` +
      '&pageType=Card' +
      `${shouldSendDefaultMessage ? '&sendDefaultMessage=1' : ''}` +
      `${source ? `&parentSource=${source}` : ''}`;
  }

  return (
    <div className={styles['container']} data-testid="ChatContainer">
      <span className={styles['close']} data-testid="ChatCloseButton">
        <IconButton icon={<IconActionClose16 />} onClick={onClose} />
      </span>
      {isLoading && (
        <span className={styles['spinner']} data-testid="spinner">
          <Spinner size={16} />
        </span>
      )}
      <iframe data-testid="ChatModal" className={styles['iframe']} src={src} onLoad={onLoad} onError={onLoad} />
    </div>
  );
};
