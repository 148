import { useSelector } from 'react-redux';

import { createCheckoutPageUrl } from 'shared/containers/AsideContacts/OnlineBookingBookButtonContainer/helpers';
import { selectDailyrentBookingCalendar, selectDailyrentBookingGuests } from 'shared/selectors/dailyrentBooking';
import { selectOfferId } from 'shared/selectors/offerData/offer/selectOfferId';

export const useDailyrentCheckoutPageUrl = () => {
  const realtyId = useSelector(selectOfferId);
  const { dates } = useSelector(selectDailyrentBookingCalendar);
  const { guestsCount } = useSelector(selectDailyrentBookingGuests);

  return createCheckoutPageUrl({ realtyId, dates, guestsCount });
};
