import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { NewbuildingBookingFormComponent, SuccessScreen, ErrorScreen } from 'shared/components/NewbuildingBookingForm';

import { useBookingForm } from './useBookingForm';

interface INewbuildingBookingFormProps {
  opened: boolean;
  onClose: () => void;
}

export const NewbuildingBookingFormContainer = ({ opened, onClose }: INewbuildingBookingFormProps) => {
  const {
    name,
    nameError,
    onNameChange,
    phone,
    phoneError,
    onPhoneChange,
    submitting,
    onSubmit,
    onRetry,
    formStatus,
    personalAgreementLink,
    privacyPolicyLink,
  } = useBookingForm();

  return (
    <ModalWindow
      open={opened}
      onClose={onClose}
      content={
        <>
          {formStatus === 'default' && (
            <NewbuildingBookingFormComponent
              phone={phone}
              onPhoneChange={onPhoneChange}
              name={name}
              nameError={nameError}
              onNameChange={onNameChange}
              submitting={submitting}
              phoneError={phoneError}
              onSubmit={onSubmit}
              personalAgreementLink={personalAgreementLink}
              privacyPolicyLink={privacyPolicyLink}
            />
          )}
          {formStatus === 'success' && <SuccessScreen onClose={onClose} />}
          {formStatus === 'error' && <ErrorScreen onClick={onRetry} />}
        </>
      }
    />
  );
};
