import { selectOfferCategory } from 'shared/selectors';
import { selectDailyrentBookingGeneral } from 'shared/selectors/dailyrentBooking';
import { isPriceElectronicTradingSelector } from 'shared/selectors/price/isPriceElectronicTradingSelector';
import { isDailyrent } from 'shared/utils/offer_helpers';

import { selectCommercialPriceAside } from './selectCommercialPriceAside';
import { selectResidentialPriceAside } from './selectResidentialPriceAside';
import { IState } from '../../store';
import { getOfferType, isCommercial } from '../../utils/offer_type';

export const selectPriceAside = (state: IState) => {
  const {
    offerData: { offer },
  } = state;
  const offerType = getOfferType(offer);
  const isPriceAuction = isPriceElectronicTradingSelector(state);
  const offerCategory = selectOfferCategory(state);
  const { price: dailyrentBookingPrice } = selectDailyrentBookingGeneral(state);

  if (isCommercial(offerType)) {
    if (isPriceAuction) {
      return null;
    }

    return selectCommercialPriceAside(state);
  }

  if (isDailyrent(offerCategory) && dailyrentBookingPrice) {
    return dailyrentBookingPrice;
  }

  return selectResidentialPriceAside(state);
};
