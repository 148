import { IState } from 'shared/store';

export const selectDailyrentBookingGeneral = (state: IState) => {
  return state.dailyrentBooking.general;
};
export const selectDailyrentBookingCalendar = (state: IState) => {
  return state.dailyrentBooking.calendar;
};
export const selectDailyrentBookingGuests = (state: IState) => {
  return state.dailyrentBooking.guests;
};
