import * as React from 'react';
import { useSelector } from 'react-redux';

import { BookingInfo } from 'shared/components/BookingInfo';
import { selectDailyrentBookingGeneral } from 'shared/selectors/dailyrentBooking';

export const BookingSummaryContainer = () => {
  const { paymentSummary, isLoading } = useSelector(selectDailyrentBookingGeneral);

  if (!paymentSummary) {
    return null;
  }

  return <BookingInfo paymentSummary={paymentSummary} isLoading={isLoading} />;
};
