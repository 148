import { UIText1 } from '@cian/ui-kit';
import { IconFunctionalCalendar16, IconActionChevronDownSmall16 } from '@cian/ui-kit-design-tokens/icons';
import cn from 'clsx';
import * as React from 'react';

import * as styles from './BookingCalendarInput.css';

interface IBookingCalendarInput {
  title: string;
  isDefaultText: boolean;
  isDisabled: boolean;
  onClick(): void;
}

export const BookingCalendarInput = (props: IBookingCalendarInput) => {
  const { onClick, title, isDefaultText, isDisabled } = props;

  return (
    <div className={cn(styles['root'], isDisabled && styles['disabled'])} data-testid="BookingCalendarInput">
      <button type="button" className={styles['content']} onClick={onClick}>
        <span className={styles['icon-container']}>
          <IconFunctionalCalendar16 color="icon-main-default" />
        </span>
        <div className={cn(styles['text-container'], { [styles['text-container_placeholder']]: isDefaultText })}>
          <UIText1>{title}</UIText1>
        </div>
        <IconActionChevronDownSmall16 color="icon-main-default" />
      </button>
    </div>
  );
};
