import { OnboardingTooltip } from '@cian/ui-kit';
import * as React from 'react';

import { ChatOnboardingContent } from 'shared/components/ChatOnboardingContent';

import { useChatOnboarding } from './hooks/useChatOnboarding';
import { TChatOnboardingType } from './types';

interface IChatOnboardingContainerProps {
  children: React.ReactElement;
  type: TChatOnboardingType;
}

// TODO: Удалить в CD-226224/CD-225475.Онбординги на месяц.
export const ChatOnboardingContainer: React.FC<IChatOnboardingContainerProps> = ({ children, type }) => {
  const { open, onboardingText, closeHandler, ref } = useChatOnboarding(type);

  return (
    <div style={{ position: 'relative' }}>
      <OnboardingTooltip
        theme="black"
        content={<ChatOnboardingContent>{onboardingText}</ChatOnboardingContent>}
        placement="left-start"
        portal={false}
        open={open}
        onClose={closeHandler}
        flip={false}
      >
        <span ref={ref}>{children}</span>
      </OnboardingTooltip>
    </div>
  );
};
