import { useSelector } from 'react-redux';

import { selectDailyrentBookingCalendar } from 'shared/selectors/dailyrentBooking';
import { useIsDailyrentBookingCalendarEnabled } from 'shared/utils/featureToggles/useIsDailyrentBookingCalendarEnabled';

export const useShowDailyrentBookingCalendar = () => {
  const { showBookingCalendar } = useSelector(selectDailyrentBookingCalendar);
  const isDailyrentBookingCalendarEnabled = useIsDailyrentBookingCalendarEnabled();

  return isDailyrentBookingCalendarEnabled && showBookingCalendar;
};
