import { DropdownPopover, Outside } from '@cian/ui-kit';
import React from 'react';

import { getSafeGuestsCount } from 'shared/utils/getSafeGuestsCount';

import * as styles from './styles.css';
import { GuestsControlButton } from '../GuestControlButton';
import { GuestsModalContent } from '../GuestsModalContent';
import { GuestsModalFooter } from '../GuestsModalFooter';
import { IGuestsControlProps } from '../types';

export function GuestsControlDesktop({
  isModalOpen,
  localValue,
  appliedValue,
  minValue,
  maxValue,
  isLoading,
  onControlClick,
  onChange,
  onResetClick,
  onApplyClick,
  onClose,
}: IGuestsControlProps) {
  // Рефы нужны для ui-kit компонента Outside, иначе ошибки в React дереве рендеринга
  const rootRef = React.useRef<HTMLDivElement>(null);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const safeLocalValue = getSafeGuestsCount(localValue, minValue, maxValue);

  return (
    <Outside active={isModalOpen} onOutside={onClose} insideRefs={[rootRef, modalRef]}>
      <div ref={rootRef}>
        <DropdownPopover
          open={isModalOpen}
          flip={true}
          sameWidth="width"
          content={
            <div className={styles['wrapper']} ref={modalRef}>
              <GuestsModalContent
                value={safeLocalValue}
                minValue={minValue}
                maxValue={maxValue}
                onChange={onChange}
                disabled={isLoading}
                needFormatValue={false}
              />
              <GuestsModalFooter onResetClick={onResetClick} onApplyClick={onApplyClick} />
            </div>
          }
        >
          <GuestsControlButton
            isActive={isModalOpen}
            minValue={minValue}
            maxValue={maxValue}
            value={appliedValue}
            onClick={onControlClick}
            disabled={isLoading}
          />
        </DropdownPopover>
      </div>
    </Outside>
  );
}
