import React from 'react';
import { useSelector } from 'react-redux';

import { updateDailyrentUrlParameters } from 'shared/containers/BookingCalendarInputContainer/helpers';
import { selectDailyrentBookingCalendar, selectDailyrentBookingGuests } from 'shared/selectors/dailyrentBooking';

/**
 * При первом рендере страницы устанавливает в URL нормализованное значение количества гостей.
 * Защищает от неадекватных значений – оставляет только допустимый диапазон.
 */
export const useSetNormalizedGuestsCountIntoUrl = () => {
  const { dates } = useSelector(selectDailyrentBookingCalendar);
  const { guestsCount, minGuestsCount, maxGuestsCount } = useSelector(selectDailyrentBookingGuests);

  React.useEffect(() => {
    const normalizedGuestsCount = guestsCount
      ? Math.max(minGuestsCount, Math.min(maxGuestsCount, guestsCount))
      : minGuestsCount;

    const newUrl = updateDailyrentUrlParameters({
      currentUrl: window.location.href,
      guestsCount: normalizedGuestsCount,
      dates: {
        checkin: dates.from,
        checkout: dates.to,
      },
    });

    history.replaceState(undefined, '', newUrl);
  }, [guestsCount, minGuestsCount, maxGuestsCount]);
};
