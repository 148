import { IState } from '../../store';
import { selectNewbuildingConsultantMessageButtonExperimentEnabled } from '../abExperiments';
import { isNewbuildingSelector, isFromBuilderSelector } from '../offerData';

export function accountNameSelector(state: IState) {
  return state.newbuildingConsultant.accountName;
}

export function chatHostSelector(state: IState) {
  return state.newbuildingConsultant.host;
}

export function selectLocation(state: IState) {
  return state.newbuildingConsultant.location;
}

// TODO: В CD-225475 в случае победы эксперимента выпилить chatAvailableSelector внести под selectConsultantMessageButtonAvailable

export function chatAvailableSelector(state: IState): boolean {
  return !!accountNameSelector(state) && isNewbuildingSelector(state) && isFromBuilderSelector(state);
}

export function isConsultantChatOpenedSelector(state: IState): boolean {
  return state.newbuildingChat.isOpen;
}

// TODO: Удалить в CD-225475
export function selectConsultantMessageButtonAvailable(state: IState): boolean {
  const { isConsultantMessageButtonEnabled } = state.featureToggle;
  const isConsultantMessageButtonExperimentEnabled = selectNewbuildingConsultantMessageButtonExperimentEnabled(state);

  return isConsultantMessageButtonEnabled && isConsultantMessageButtonExperimentEnabled && chatAvailableSelector(state);
}
