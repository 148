import { ca } from '@cian/analytics';

export function trackDropDownOpen() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentGuests',
    action: 'Show',
    label: 'Popup',
    useLastProducts: true,
  });
}

const modeToLabel = {
  increase: 'Plus',
  decrease: 'Minus',
  manual: '',
};
export function trackGuestsLocalChange(mode: 'increase' | 'decrease' | 'manual') {
  ca('eventSite', {
    event: 'oldevent',
    name: 'oldevent',
    category: 'DailyrentGuests',
    action: 'Click',
    label: modeToLabel[mode],
    useLastProducts: true,
  });
}

export function trackGuestsApply(guests: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentGuests',
    action: 'Click',
    label: 'Confirm',
    page: {
      pageType: 'Card',
      ...(guests ? { extra: { guests } } : {}),
    },
    useLastProducts: true,
  });
}

export function trackGuestsReset() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentGuests',
    action: 'Click',
    label: 'Reset',
    useLastProducts: true,
  });
}
