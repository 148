import { prepareUserData } from '@cian/newbuilding-chat-component';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getWebimUserDataSelector } from 'shared/selectors/getWebimUserData';
import { accountNameSelector, chatHostSelector, selectLocation } from 'shared/selectors/newbuildingConsultant';

// TODO: Удалить параметр в CD-225475.
export const useWebimProps = (isKpnExperiment = false) => {
  const user = useSelector(getWebimUserDataSelector);
  const accountName = useSelector(accountNameSelector);
  const host = useSelector(chatHostSelector);
  const location = useSelector(selectLocation);

  const locationProp = isKpnExperiment ? location : undefined;

  return useMemo(
    () => ({
      accountName: accountName as string,
      userData: prepareUserData(user),
      host: host || undefined,
      location: locationProp,
    }),
    [accountName, host, locationProp, user],
  );
};
