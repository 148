import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsOfferPublished, selectOffer } from 'shared/selectors';
import { regionIdSelector } from 'shared/selectors/offerData/geo/regionIdSelector';

import { SubsidisedMortgageModalContainer } from './SubsidisedMortgageModalContainerLoadable';
import { SubsidisedMortgageSendOfferModalContainer } from './SubsidisedMortgageSendOfferModalContainerLoadable';
import { trackSubsidisedMortgageBannerShow } from './tracking';
import { SubsidisedMortgageBanner } from '../../components/SubsidisedMortgageBanner';
import { useApplicationContext } from '../ApplicationContext';

export type TSubsidisedMortgageInfo = {
  title: string;
  subtitle: string;
  sendOfferModalTitle: string;
  sendOfferModalText: string;
  modalTitle: string;
  texts: string[];
  submitApplicationUrl: string;
  learnMoreUrl: string;
  defaultMessage: string;
  messagePayload: string;
  excludedRegions: number[];
};

export const SubsidisedMortgageBannerContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { isSubsidisedMortgage } = useSelector(selectOffer);
  const isPublished = useSelector(selectIsOfferPublished);
  const regionId = useSelector(regionIdSelector);

  const isAvailable = config.get<boolean>('frontend-offer-card.subsidizedMortgage.Enabled');

  const modalWindow = React.useMemo(() => {
    if (!isModalOpen) {
      return null;
    }

    if (isSubsidisedMortgage) {
      return <SubsidisedMortgageModalContainer onClose={() => setIsModalOpen(false)} />;
    }

    return <SubsidisedMortgageSendOfferModalContainer onClose={() => setIsModalOpen(false)} />;
  }, [isModalOpen, isSubsidisedMortgage]);

  const info = config.get<TSubsidisedMortgageInfo>('subsidizedMortgage.bannerInfo');

  if (!isPublished || (!isAvailable && !isSubsidisedMortgage) || !info || info.excludedRegions.includes(regionId)) {
    return null;
  }

  const { title, subtitle } = info;

  return (
    <>
      <SubsidisedMortgageBanner
        onShow={trackSubsidisedMortgageBannerShow}
        onClick={() => setIsModalOpen(true)}
        title={title}
        subtitle={subtitle}
      />
      {modalWindow}
    </>
  );
};
