import { TChatOnboardingType } from './types';

const ONBOARDING_COOKIE_NAME_MAP: Record<TChatOnboardingType, string> = {
  builder: 'frontend-offer-card.builder_chat_onboarding_shown',
  consultant: 'frontend-offer-card.consultant_chat_onboarding_shown',
};

const CHAT_ONBOARDING_TEXT: Record<TChatOnboardingType, string> = {
  builder: 'Newbuilding.CianChatOnboarding.Text',
  consultant: 'Newbuilding.WebimChatOnboarding.Text',
};

export { ONBOARDING_COOKIE_NAME_MAP, CHAT_ONBOARDING_TEXT };
