import { format, startOfToday } from 'date-fns';

import { checkDailyrentPrice } from 'shared/actions/checkDailyrentPrice/actions';
import { EDailyrentBookingCalendarActionTypes } from 'shared/actions/dailyrentBookingCalendar/types';
import { updateDailyrentUrlParameters } from 'shared/containers/BookingCalendarInputContainer/helpers';
import { ICheckPriceRequest } from 'shared/repositories/dailyrent/v2/check-price';
import { selectDailyrentBookingGuests, selectDailyrentBookingCalendar } from 'shared/selectors/dailyrentBooking';
import { selectOfferId } from 'shared/selectors/offerData/offer/selectOfferId';
import { fetchGetOfferCalendar } from 'shared/services/fetchGetOfferCalendar';
import { IThunkActionCreator } from 'shared/store';
import { trackCalendarDatesSubmit } from 'shared/tracking/dailyrentBookingCalendar';
import { TDaysAvailability } from 'shared/types/bookingCalendar';
import { actionGenerator } from 'shared/utils/redux/actionGenerator';

export const toggleCalendarModal = actionGenerator<
  EDailyrentBookingCalendarActionTypes.ToggleCalendarModal,
  boolean | void
>(EDailyrentBookingCalendarActionTypes.ToggleCalendarModal);

const setDaysAvailability = actionGenerator<
  EDailyrentBookingCalendarActionTypes.SetDaysAvailability,
  TDaysAvailability
>(EDailyrentBookingCalendarActionTypes.SetDaysAvailability);

const setInitialLoading = actionGenerator<EDailyrentBookingCalendarActionTypes.SetInitialLoading, boolean>(
  EDailyrentBookingCalendarActionTypes.SetInitialLoading,
);

export const setError = actionGenerator<EDailyrentBookingCalendarActionTypes.SetError, string>(
  EDailyrentBookingCalendarActionTypes.SetError,
);

export const setDates = actionGenerator<
  EDailyrentBookingCalendarActionTypes.SetDates,
  { from: string | undefined; to: string | undefined }
>(EDailyrentBookingCalendarActionTypes.SetDates);

/** Запрашивает календарь доступности */
const loadCalendarDaysAvailability = (): IThunkActionCreator<Promise<void>> => async (dispatch, getState, context) => {
  const state = getState();
  const offerRealtyId = selectOfferId(state);
  const today = startOfToday();
  const startDate = format(today, 'yyyy-MM-dd');

  dispatch(setInitialLoading(true));

  try {
    const { calendar } = await fetchGetOfferCalendar(context, { offerRealtyId, startDate });
    dispatch(setDaysAvailability(calendar));
  } catch (err) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setInitialLoading(false));
  }
};

/** Изменение дат в календаре */
export const calendarDatesChange =
  ({ dates }: Omit<ICheckPriceRequest, 'offerRealtyId' | 'guestsCount'>): IThunkActionCreator<Promise<void | string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const offerId = selectOfferId(state);
    const { guestsCount } = selectDailyrentBookingGuests(state);

    const errorMessage = await dispatch(
      checkDailyrentPrice({ dates: { start: dates.start, end: dates.end }, guestsCount }),
    );

    if (errorMessage) {
      return;
    }

    const newUrl = updateDailyrentUrlParameters({
      currentUrl: window.location.href,
      dates: {
        checkin: dates.start,
        checkout: dates.end,
      },
      guestsCount,
    });

    history.replaceState(undefined, '', newUrl);
    dispatch(toggleCalendarModal(false));
    dispatch(setDates({ from: dates.start, to: dates.end }));
    dispatch(setError(''));
    trackCalendarDatesSubmit(offerId);
  };

export const openBookingCalendar = (): IThunkActionCreator<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const { dates } = selectDailyrentBookingCalendar(state);
  const { guestsCount } = selectDailyrentBookingGuests(state);
  const { from, to } = dates;

  dispatch(toggleCalendarModal(true));
  await dispatch(loadCalendarDaysAvailability());
  if (from && to) {
    dispatch(checkDailyrentPrice({ dates: { start: from, end: to }, guestsCount }));
  }
};
